import React from 'react'

const Footer = () => (
  <footer>
    <div className="footer-content">
      <p>
        made by&nbsp;
        <a
          href="https://github.com/bdhowald"
          target="_blank"
          rel="noopener noreferrer"
          className="self-link"
        >
          @bdhowald
        </a>
      </p>
    </div>
  </footer>
)

export default Footer
