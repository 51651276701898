export const BUS_LANE_CAMERA_VIOLATION_HUMANIZED_DESCRIPTION =
  'Bus Lane Violation'
export const BUS_LANE_CAMERA_VIOLATION_CODE = '5'
export const MOBILE_BUS_LANE_CAMERA_VIOLATION_HUMANIZED_DESCRIPTION =
  'Mobile Bus Lane Violation'
export const MOBILE_BUS_LANE_CAMERA_VIOLATION_CODE = '12'
export const RED_LIGHT_CAMERA_VIOLATION_HUMANIZED_DESCRIPTION =
  'Failure to Stop at Red Light'
export const RED_LIGHT_CAMERA_VIOLATION_CODE = '7'
export const SCHOOL_ZONE_SPEED_CAMERA_VIOLATION_HUMANIZED_DESCRIPTION =
  'School Zone Speed Camera Violation'
export const SCHOOL_ZONE_SPEED_CAMERA_VIOLATION_CODE = '36'
